<template>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="佣金确认" name="first">
            <el-card>
                <template #header>
                    <div class="headChange">
                        <div>
                            <el-button type="primary" plain :icon="Plus" @click="settlement">申请结算</el-button>
                        </div>
                        <div @click="refreshTab('first')">
                            <el-button round><el-icon>
                                    <Refresh />
                                </el-icon>刷新</el-button>
                        </div>
                    </div>
                </template>

                <el-table :data="applyTableData" v-loading="isloading" stripe style="width: 100%" :row-style="rowStyle"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" :selectable="isSelectable" />
                    <el-table-column prop="month" label="分润月份" />
                    <el-table-column prop="title" label="佣金项目" />
                    <el-table-column prop="should_checkout_amount" label="应结佣金" />
                    <el-table-column prop="agent_name" label="归属代理" />
                    <el-table-column prop="is_confirmed_text" label="确认状态">
                        <template #default="scope">
                            <div :class="getAuditStatusClass(scope.row.is_confirmed)">{{ scope.row.is_confirmed_text }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="is_applied_text" label="申请状态">
                        <template #default="scope">
                            <div :class="getAuditStatusClass(scope.row.is_applied)">{{ scope.row.is_applied_text }}
                            </div>
                        </template>
                    </el-table-column>
                     <el-table-column prop="audit_status_text" label="审核状态">
                        <template #default="scope">
                            <div :class="getAuditStatusClass(scope.row.audit_record?.audit_status)">{{ scope.row.audit_record?.audit_status_text }}
                              <el-tooltip
                                  v-if="scope.row.audit_record && scope.row.audit_record?.audit_status == 2"
                                  effect="dark"
                                  :content="'原因：' + scope.row.audit_record?.audit_remark || '-'"
                                  placement="top"
                              >
                                <el-icon>
                                  <QuestionFilled />
                                </el-icon>
                              </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                  <el-table-column prop="settled_at" label="结算时间" width="220" show-overflow-tooltip>
                    <template #default="scope">
                      {{ scope.row.audit_record?.settled_at || '-' }}
                    </template>
                  </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template #default="scope">
                            <el-button type="primary" size="small" plain
                                @click="downloadFile(scope.row.detail_sheet_url)">下载明细表</el-button>
                            <el-button v-if="scope.row.is_confirmed_text === '待确认'" type="warning" size="small"
                                @click="confirmCommission(scope.row.id)" plain>确认</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination background layout="total, prev, pager, next, jumper, slot" :total="pageinfo.total"
                        @current-change="pageChange" :page-size="pageinfo.perPage"
                        :current-page="pageinfo.currentPage" />
                </div>
            </el-card>
            <el-dialog v-model="applyFormVisible" title="申请结算" width="560px" draggable>
                <el-form :model="applyForm" :rules="rules" ref="formRef" class="custom-form" label-width="120px">
                    <el-form-item label="归属代理：" prop="agent_name">{{ applyForm.agent_name }}</el-form-item>
                    <el-form-item label="分润月份：" prop="months">{{ applyForm.months.join(', ') }}</el-form-item>
                    <el-form-item label="佣金项目：" prop="titles">{{ applyForm.titles.join(', ') }}</el-form-item>
                    <el-form-item label="应结佣金：" prop="should_checkout_amount">{{ applyForm.should_checkout_amount
                        }}</el-form-item>
                    <el-form-item label="电子发票：" prop="detail_sheet" style="min-height: 240px;">
                        <el-upload v-if="!applyForm.detail_sheet" class="upload-demo" drag :http-request="uploadFile"
                                                       accept=".pdf,.jpg,.jpeg,.png,.webp"
                            style="width: 240px;">
                            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                            <div class="el-upload__text">拖动文件到此处或<em>点击上传</em></div>
                        </el-upload>
                          <div v-else style="color: #67C23A;display: flex;flex-direction: column ;align-items: flex-start;width: 240px;height: 100%;gap: 4px;">
                          <div style="display: flex;justify-content: space-between;font-size: 12px;align-items: center;width: 100%;">
                            <span>上传成功</span>
                            <el-button size="small" plain type="warning" :icon="CloseBold" @click="clearFile">重新上传</el-button>
                          </div>
                          <div>
                            <a :href="applyForm.detail_sheet_url" target="_blank">{{ applyForm.detail_sheet_url.split('/').pop() }}</a>
                          </div>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="dialog-footer">
                    <el-button @click="applyFormVisible = false" style="width: 80px;">取消</el-button>
                    <el-button type="primary" @click="confirmSubmit" style="width: 80px;">提交申请</el-button>
                </div>
            </el-dialog>
        </el-tab-pane>


        <el-tab-pane label="申请记录" name="second">
            <el-card>
                <template #header>
                    <div class="headChange">
                        <div></div>
                        <div>
                            <div @click="refreshTab('second')">
                                <el-button round><el-icon>
                                        <Refresh />
                                    </el-icon>刷新</el-button>
                            </div>
                        </div>
                    </div>
                </template>
                <el-table :data="auditTableData" v-loading="isloading" stripe style="width: 100%" :row-style="rowStyle">
                    <el-table-column label="分润月份">
                        <template #default="scope">
                            <div v-for="(item, index) in scope.row.confirm_records" :key="index">{{ item.month }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="佣金项目">
                        <template #default="scope">
                            <div v-for="(item, index) in scope.row.confirm_records" :key="index">{{ item.title }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="should_checkout_amount" label="应结佣金" />
                    <el-table-column prop="agent_name" label="归属代理">
                        <template #default="scope">
                            <div>{{ scope.row.confirm_records[0].agent_name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="audit_status_text" label="审核状态">
                        <template #default="scope">
                            <div :class="getAuditStatusClass(scope.row.audit_status)">{{ scope.row.audit_status_text }}
                              <el-tooltip
                                  v-if="scope.row.audit_status == 2"
                                  effect="dark"
                                  :content="'原因：' + scope.row.audit_remark || '-'"
                                  placement="top"
                              >
                                <el-icon>
                                  <QuestionFilled />
                                </el-icon>
                              </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                  <el-table-column prop="settled_at" label="结算时间" width="220" show-overflow-tooltip>
                    <template #default="scope">
                      {{ scope.row.settled_at || '-' }}
                    </template>
                  </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template #default="scope">
                          <el-button type="primary" plain size="small" @click="handleView(scope.row)">查看</el-button>
                            <el-button plain v-if="scope.row.audit_status != 1" type="warning" size="small"
                                @click="handleEdit(scope.row)">修改申请</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination background layout="total, prev, pager, next, jumper, slot" :total="pageinfo.total"
                        @current-change="pageChange" :page-size="pageinfo.perPage"
                        :current-page="pageinfo.currentPage" />
                </div>
            </el-card>

            <el-dialog v-model="auditFormVisible" :title="dialogTitle" width="560px" draggable>
                <el-form :model="auditForm" :rules="audit_rules" ref="formRefs" class="custom-form" label-width="120px">
                    <el-form-item label="归属代理：" prop="agent_name">{{ auditForm.agent_name }}</el-form-item>
                    <el-form-item label="分润月份：" prop="months">{{ auditForm.months.join(', ') }}</el-form-item>
                    <el-form-item label="佣金项目：" prop="titles">{{ auditForm.titles.join(', ') }}</el-form-item>
                    <el-form-item label="应结佣金：" prop="should_checkout_amount">{{ auditForm.should_checkout_amount
                        }}</el-form-item>
                    <el-form-item label="电子发票：" v-if="isViewMode">
                        <template v-if="isImageUrl(auditForm.imgUrl)">
                            <el-image style="width: 100px; height: 100px" :src="auditForm.imgUrl"
                                :preview-src-list="previewSrcList" fit="cover" />
                        </template>
                        <template v-else>
                            <a :href="auditForm.imgUrl" target="_blank">下载文件</a>
                        </template>
                    </el-form-item>
                    <el-form-item label="电子发票：" v-else prop="detail_sheet" style="min-height: 240px;">
                        <el-upload v-if="!auditForm.detail_sheet" drag :http-request="uploadFiles"
                             accept=".pdf,.jpg,.jpeg,.png,.webp"
                            style="width: 240px;">
                            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                            <div class="el-upload__text">拖动文件到此处或<em>点击上传</em></div>
                        </el-upload>
                          <div v-else style="color: #67C23A;display: flex;flex-direction: column ;align-items: flex-start;width: 240px;height: 100%;gap: 4px;">
                          <div style="display: flex;justify-content: space-between;font-size: 12px;align-items: center;width: 100%;">
                            <span>上传成功</span>
                            <el-button size="small" plain type="warning" :icon="CloseBold" @click="auditClearFile">重新上传</el-button>
                          </div>
                          <div>
                            <a :href="auditForm.detail_sheet_url" target="_blank">{{ auditForm.detail_sheet_url.split('/').pop() }}</a>
                          </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="审批结果：" v-if="isViewMode"
                        :class="getAuditStatusClass(auditForm.audit_status)">{{
        auditForm.audit_status_text }}</el-form-item>
                    <el-form-item label="审批备注：" v-if="isViewMode">{{ auditForm.audit_remark }}</el-form-item>
                </el-form>
                <div class="dialog-footer" v-if="!isViewMode">
                    <el-button @click="auditFormVisible = false" style="width: 80px;">取消</el-button>
                    <el-button type="primary" @click="confirmSubmits" style="width: 80px;">提交修改</el-button>
                </div>
                <div class="dialog-footer" v-else>
                    <el-button @click="auditFormVisible = false" style="width: 80px;">好的</el-button>
                </div>
            </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="开票须知" name="third">
            <div style="color:#73767a ;margin-left: 60px;">
                <h1>（一）开票抬头：</h1>
                <p>名称：对米（广州）网络科技有限公司</p>
                <p>税号：91440101MA5CC6439H</p>
                <p>地址：广州市南沙区丰泽东路106号（自编1号楼）电话：15970522666</p>
                <p>开户行：中国工商银行股份有限公司广州德政中路支行</p>
                <p>账号：3602003709200178577</p>
                <h1>（二）开票项目：服务费 、技术服务费</h1>
                <h4>注：请开具增值税专用发票</h4>
                <p style="color: #E6A23C;">1、每月实际结算分润不满1000元则不结，直至分润累积满1000元再结算。</p>
                <p>2、为了能让您的开票金额和打款金额一致，您可以根据佣金金额，自己计算实际开票金额。税点计算公式如下：</p>
                <p style="color: #E6A23C;">（1）增值税专用发票（税点：1%）：开票金额 = 实际应结佣金 X 0.95</p>
                <p style="color: #E6A23C;">（2）增值税专用发票（税点：3%）：开票金额 = 实际应结佣金 X 0.97</p>
                <p style="color: #E6A23C;">（3）增值税专用发票（税点：6%）：开票金额 = 实际应结佣金</p>
                <p>3、佣金、发票问题请发邮件至邮箱：377158908@qq.com</p>
            </div>
        </el-tab-pane>
    </el-tabs>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import {UploadFilled, CloseBold, Refresh, Plus, QuestionFilled} from '@element-plus/icons-vue';

const { proxy } = getCurrentInstance();
const dialogTitle = ref('');
const isViewMode = ref(true);
const applyTableData = ref([]);
const auditTableData = ref([]);
const previewSrcList = ref([]);
const applyFormVisible = ref(false);
const auditFormVisible = ref(false);
const isloading = ref(false);

const applyForm = ref({
    agent_name: '',
    months: [],
    titles: [],
    should_checkout_amount: '',
    detail_sheet: '',
    detail_sheet_url: '',
    id: []
});

const selectedRows = ref([]);

const auditForm = ref({
    agent_name: '',
    months: [],
    titles: [],
    should_checkout_amount: '',
    detail_sheet: '',
    detail_sheet_url: '',
    audit_status: '',
    audit_status_text: '',
    audit_remark: '',
    id: '',
    imgUrl: ''
});

const pageinfo = ref({
    perPage: 0,
    total: 0,
    totalPages: 0,
    currentPage: 1,
});

const checkPage = ref('first');

const pageChange = (page) => {
    checkPage.value === 'first' ? hasSearch(page) : auditHasSearch(page);
};

const hasSearch = (page) => {
    isloading.value = true;
    commissionList({ page });
};

const auditHasSearch = (page) => {
    isloading.value = true;
    commissionAuditList({ page });
};

const commissionList = async (info) => {
    try {
        isloading.value = true;
        const response = await proxy.$api.agent.commission.commissionList(info);
        applyTableData.value = response.data;
        pageinfo.value = {
            perPage: response.pagination.perPage,
            total: response.pagination.total,
            totalPages: response.pagination.totalPages,
            currentPage: response.pagination.currentPage
        };
    } catch (error) {
        proxy.$message.error('获取佣金确认列表失败');
    } finally {
        isloading.value = false;
    }
};

const commissionAuditList = async (info) => {
    try {
        isloading.value = true;
        const response = await proxy.$api.agent.commission.commissionAuditList(info);
        auditTableData.value = response.data;
        pageinfo.value = {
            perPage: response.pagination.perPage,
            total: response.pagination.total,
            totalPages: response.pagination.totalPages,
            currentPage: response.pagination.currentPage
        };
    } catch (error) {
        proxy.$message.error('获取佣金审核列表失败');
    } finally {
        isloading.value = false;
    }
};

const handleView = (row) => {
    dialogTitle.value = '查看申请单';
    isViewMode.value = true;
    loadDataToForm(row);
    auditFormVisible.value = true;
};

const handleEdit = (row) => {
    dialogTitle.value = '修改申请单';
    isViewMode.value = false;
    loadDataToForm(row);
    auditFormVisible.value = true;
};

const loadDataToForm = (row) => {
    const { confirm_records, should_checkout_amount, audit_status, audit_status_text, id, invoice_file_url, invoice_file, audit_remark } = row;
    auditForm.value.agent_name = confirm_records[0].agent_name;
    auditForm.value.months = confirm_records.map(record => record.month);
    auditForm.value.titles = confirm_records.map(record => record.title);
    auditForm.value.should_checkout_amount = should_checkout_amount;
    auditForm.value.audit_status_text = audit_status_text;
    auditForm.value.audit_status = audit_status;
    auditForm.value.id = id;
    auditForm.value.imgUrl = invoice_file_url;
    previewSrcList.value = [invoice_file_url];
    auditForm.value.detail_sheet = invoice_file
    auditForm.value.detail_sheet_url = invoice_file_url
    auditForm.value.audit_remark = audit_remark || '-';
};

const formRefs = ref(null);

const confirmSubmits = () => {
    formRefs.value.validate((valid) => {
        if (valid) {
            proxy.$api.agent.commission.applyCommissionEdit({ id: auditForm.value.id, invoice_file: auditForm.value.detail_sheet })
                .then(res => {
                    auditTableData.value = res.data;
                    ElMessage.success('提交成功');
                    auditFormVisible.value = false;
                    commissionAuditList();
                })
                .catch(() => ElMessage.error('提交失败，请稍后重试'));
        } else {
            ElMessage.error('表单验证失败');
        }
    });
};

const uploadFiles = async (options) => {
    const formData = new FormData();
    formData.append('file', options.file);
    try {
        const response = await proxy.$api.agent.commission.uploadFile(formData);
        auditForm.value.detail_sheet = response.data.id;
        auditForm.value.detail_sheet_url = response.data.url;
        ElMessage.success('文件上传成功');
    } catch (error) {
        ElMessage.error('文件上传失败，请稍后重试');
    }
};

const auditClearFile = () => {
    auditForm.value.detail_sheet = '';
    auditForm.value.detail_sheet_url = '';
};

const handleSelectionChange = (val) => {
    selectedRows.value = val;
    applyForm.value.id = val.map(item => item.id);
    applyForm.value.months = val.map(item => item.month);
    applyForm.value.titles = val.map(item => item.title);
    applyForm.value.agent_name = val.length > 0 ? val[0].agent_name : '';
    applyForm.value.should_checkout_amount = val.reduce((sum, item) => sum + parseFloat(item.should_checkout_amount), 0).toFixed(2);
};

const isSelectable = (row) => {
    return row.is_confirmed_text === '已确认' && row.is_applied_text === '待申请';
};

const uploadFile = async (options) => {
    const formData = new FormData();
    formData.append('file', options.file);
    try {
        const response = await proxy.$api.agent.commission.uploadFile(formData);
        applyForm.value.detail_sheet = response.data.id;
        applyForm.value.detail_sheet_url = response.data.url;
        ElMessage.success('文件上传成功');
    } catch (error) {
        ElMessage.error('文件上传失败，请稍后重试');
    }
};

const clearFile = () => {
    applyForm.value.detail_sheet = '';
    applyForm.value.detail_sheet_url = '';
};

const downloadFile = (url) => {
    window.open(url);
};


// 判断是否为图片链接
const isImageUrl = (url) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const extension = url.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
};


const formRef = ref(null);

const confirmSubmit = () => {
    formRef.value.validate((valid) => {
        if (valid) {
            ElMessageBox.confirm('是否确认提交申请？', '确认提交', { confirmButtonText: '确认', cancelButtonText: '取消', type: 'warning' })
                .then(() => submitAudit())
                .catch(() => ElMessage.info('已取消提交'));
        } else {
            return false;
        }
    });
};

const submitAudit = async () => {
    try {
        const response = await proxy.$api.agent.commission.applyCommission({ confirm_ids: applyForm.value.id, invoice_file: applyForm.value.detail_sheet });
        if (response.status === 200) {
            ElMessage.success('提交成功');
            applyFormVisible.value = false;
            commissionList();
        } else {
            ElMessage.error('提交失败，请稍后重试');
        }
    } catch (error) {
        ElMessage.error('提交失败，请稍后重试');
    }
};

const rowStyle = () => ({ height: '60px' });

const settlement = () => {
    if (selectedRows.value.length === 0) {
        ElMessage.warning('请先勾选要结算的项目');
        return;
    }
    applyFormVisible.value = true;
};

const confirmCommission = (id) => {
    ElMessageBox.confirm('请核对佣金明细表数据正确无误后，再点击确认', '确认操作？', {confirmButtonText: '我已核对并确认无误', cancelButtonText: '取消', type: "warning" })
        .then(async () => {
            try {
                const res = await proxy.$api.agent.commission.commissionConfirm({ id });
                if (res.status === 200) {
                    ElMessage.success('完成确认');
                    commissionList();
                } else {
                    ElMessage.error('确认失败，请稍后重试');
                }
            } catch (error) {
                ElMessage.error('确认失败，请稍后重试');
            }
        })
        .catch(() => {
            // ElMessage.info('取消操作');
        });
};

const getAuditStatusClass = (status) => {
    switch (status) {
        case 0:
            return 'pending-status';
        case 1:
            return 'approved-status';
        case 2:
            return 'rejected-status';
        default:
            return '';
    }
};

const rules = {
    detail_sheet: [{ required: true, message: '请先上传发票', trigger: 'change' }],
};

const audit_rules = {
    detail_sheet: [{ required: true, message: '请先上传发票', trigger: 'change' }],
};



const activeName = ref('first')

//待审核数据
// const todoCount = ref('0')
// const todoList = async () => {
//     try {
//         const response = await proxy.$api.agent.commission.todoList();
//         todoCount.value = response.data.data.to_audit_count
//     } catch (error) {
//         proxy.$message.error('获取待审核数据失败'); // 请求失败处理
//     }
// }


// 记录已加载的 Tab 页
const loadedTabs = ref(new Set())
// 处理 Tab 点击事件
const handleClick = (tab) => {
    // todoList();
    checkPage.value = tab.paneName
    if (!loadedTabs.value.has(tab.paneName)) {
        pageChange(1);
        loadedTabs.value.add(tab.paneName); // 标记该 Tab 已加载
    }
}

const refreshTab = (tabName) => {
    if (tabName === 'first') {
        hasSearch(1);
    } else if (tabName === 'second') {
        auditHasSearch(1);
    }
}

onMounted(() => {
    commissionList();
    loadedTabs.value.add('first'); // 初始化第一个 Tab
});
</script>

<style scoped>
.headChange {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-right: 60px;
    height: 40px;
}

.change {
    display: flex;
    align-items: center;
    color: #79bbff;
    cursor: pointer;
}

.change:hover {
    color: #f3d19e;
}

.header-button {
    display: flex;
    justify-content: end;
    margin-right: 60px;
}

.custom-form .el-form-item {
    width: 360px;
}

.upload-success {
    color: #67C23A;
    display: flex;
    align-items: center;
}

.dialog-footer {
    display: flex;
    justify-content: center;
}

.pending-status {
    color: #999999;
}

.approved-status {
    color: #67C23A;
}

.rejected-status {
    color: #F56C6C;
}

.pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
